import React from 'react';
import { isAuthenticated, login, getIdToken } from "../utils/auth"
import Layout from '../components/Layout';

const WebsitePage = () => {

    const [announcement, setAnnouncement] = React.useState("");

    const editAnnouncement = async () => {

        const token = getIdToken();

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Authorization': 'Bearer ' + token }
        };

        let url = new URL(process.env.GATSBY_ANNOUNCEMENT_URL);
        url.searchParams.append("announcement", announcement);

        try {
            const response = await fetch(url, requestOptions)
            if(response.status === 200)
            {
                setAnnouncement("");
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleAnnouncementChange = (event) => {
        const value = event.target.value;

        setAnnouncement(value);
    }

    const getAnnouncement = async () => {

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'GET',
        };

        try {
            const response = await fetch(process.env.GATSBY_ANNOUNCEMENT_URL, requestOptions);
            const responseJson = await response.json();

            setAnnouncement(responseJson.announcement);
        }
        catch (err) {
            console.log('error', err)
        }
    }

    const handleWebsiteRefresh = async () => {

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            mode: 'no-cors'
        };

        try {
            await fetch(process.env.GATSBY_BWCREATIONS_REBUILD_WEBHOOK_URL, requestOptions)
        }
        catch (err) {
            console.log('error', err)
        }
    }

    if (!isAuthenticated()) {
        login()
        return <p>Redirecting to login...</p>
    }

    return (
        <Layout fullMenu>
            <section id="banner">
                <div className="inner">
                    <div className="logo">
                        <span className="icon fa-diamond"></span>
                    </div>
                    <h2>Website Handy Links</h2>
                </div>
                <div className="wrapper">
                    <div className="inner" id="announcement">
                        <ul className="actions">
                            <li><a href="#refresh-website" className="button primary fit small" onClick={() => handleWebsiteRefresh()}>Rebuild BWCreations.Store</a></li>
                        </ul>
                        <div className="col-12">
                            <h4>Edit Announcement</h4>
                        </div>
                        <div className="col-12">
                            <ul className="alt">
                                <li><label htmlFor="announcement">Announcement</label></li>
                                <li><textarea name="announcement" id="announcement" rows="3" value={announcement} onChange={(event) => handleAnnouncementChange(event)}></textarea></li>
                            </ul>
                        </div>
                        <div className="col-12">
                            <ul className="alt actions">
                                <li><a href="#announcement" className="button primary fit small" onClick={() => getAnnouncement()}>Get Announcement</a></li>
                                <li><a href="#announcement" className="button primary fit small" onClick={() => editAnnouncement()}>Edit Announcement</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default WebsitePage;